import React, { useRef, useState } from 'react';
import PropTypes from 'prop-types';
import { withTheme, withStyles, Typography, Select, MenuItem, IconButton } from '@material-ui/core';
import SearchSelector from '../../../UI/SearchSelector/SearchSelector';
import { collectionIds, getCollectionColour, getFontColourByBackground, getIconComponent } from '../../../../utilities';
import HierarchySearchSelector from '../../../UI/SearchSelector/HierarchySearchSelector';
import axiosCerebrum from '../../../../axios-cerebrum';
import { getPlatformSettings } from '../../../../permissionChecker';
import UserChip from '../../../UI/Chips/UserChip';
import '@toast-ui/editor/dist/toastui-editor-viewer';
import '@toast-ui/editor/dist/toastui-editor.css';
import '@toast-ui/editor/dist/theme/toastui-editor-dark.css';
import { Editor as MDEditor } from '@toast-ui/react-editor';

const styles = theme => ({
  root: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'flex-start',
    marginBottom: 40,
  },
  titleText: {
    flex: '0 0 200px',
    fontSize: 16,
    marginTop: 8,
    color: theme.palette.primary.main
  },
  selector: {
    ...theme.components.selector,
  },
  editTypeSelector: {
    flexGrow: 0,
    flexShrink: 0,
    width: 200,
    marginRight: 80,
    height: 51
  },
  selectedContainer: {
    display: 'flex',
    alignItems: 'center',
    flexDirection: 'row',
    flexWrap: 'wrap',
    marginTop: 8,
    paddingLeft: 16
  },
  selectedContainerText: {
    fontSize: 12,
    marginRight: 8,
    marginBottom: 8,
  },
  valueChip: {
    fontSize: 12,
    height: 24,
    padding: '0 8px',
    marginBottom: 8,
    marginRight: 8,
    borderRadius: 12,
    // backgroundColor: theme.palette.chip.main,
    // border: `1px solid ${theme.palette.chipBorder.main}`,
    // color: theme.palette.primaryText.main,
    display: 'flex',
    alignItems: 'center',
    maxWidth: 200
  },
  valueClearButton: {
    padding: 0,
    marginLeft: 8
  }
})

function PropertyItem(props) {

  const {
    classes,
    theme,
    property,
    state,
    dispatch,
    onRemoveProperty,
    preloadDetailMap,
  } = props;

  const [searchValue, setSearchValue] = useState('');
  let userFilter = getPlatformSettings(4008)?.value;
  const MDRef = useRef();

  let {
    field,
    operation,
    values,
    valueDetails
  } = state.propertyValues[property.id];

  const getValidOperations = () => {
    if (['verified_for', 'not_verified_for', collectionIds.category, collectionIds.classification].includes(property.id)) {
      return ['REPLACE', 'CLEAR'];
    }
    if (property.id === 'description') {
      return ['REPLACE', 'CLEAR'];
    }
    return ['ADD_TO_EXISTING', 'REPLACE', 'CLEAR'];
  }

  const onSelectInstance = async instance => {
    if (values.includes(instance.id)) return;
    if (!instance.object?.name && instance.parent_id_srt === collectionIds.category) {
      await axiosCerebrum
        .get(`/api/collectioninstances/${instance.id}`)
        .then(response => { instance = { ...instance, ...response.data } })
        .catch(error => { console.error(error) })
    }
    let isSingle = [collectionIds.category, collectionIds.classification].includes(property.id)
    let newValue = {
      field,
      operation,
      values: isSingle ? [instance.id] : [...values, instance.id],
      valueDetails: isSingle ? [instance] : [...valueDetails, instance]
    }
    dispatch({
      type: 'set_property_values',
      propertyValues: {
        ...state.propertyValues,
        [property.id]: newValue
      }
    })
    preloadDetailMap.current[property.id] = newValue;
  }

  const getInputComponent = () => {
    if ([collectionIds.category, collectionIds.domain, collectionIds.classification, 'verified_for', 'not_verified_for'].includes(property.id)) {
      let collectionId = property.id;
      if (['verified_for', 'not_verified_for'].includes(property.id)) collectionId = collectionIds.verifiedUseCase;

      return (
        <HierarchySearchSelector
          searchValue={searchValue}
          setSearchValue={setSearchValue}
          rootId={collectionId}
          fq={`parent_id_srt:${collectionId}` + (values?.length > 0 ? ` AND -id:(${values.join(' OR ')})` : '')}
          rootLabel={'collection'}
          onResultClick={item => {
            onSelectInstance(item)
          }}
          height={51}
          clearable
          testID={`add-property-search-${property.id}`}
          placeholder={`Search for ${['verified_for', 'not_verified_for'].includes(property.id) ? 'use case' : property.name_txt}(s)`}
          isItemSelected={item => {
            return values.includes(item.id)
          }}
          isItemDisabled={item => {
            if (property.id === 'verified_for') {
              let notVerifiedValues = state.propertyValues['not_verified_for']?.values;
              if (notVerifiedValues && notVerifiedValues.includes(item.id)) {
                return true;
              }
            }
            if (property.id === 'not_verified_for') {
              let verifiedValues = state.propertyValues['verified_for']?.values;
              if (verifiedValues && verifiedValues.includes(item.id)) {
                return true;
              }
            }
            return false;
          }}
          borderColour={values.length > 0 ? undefined : theme.palette.error.main}
        />
      )
    }
    if (property.id === 'description') {
      return (
        <MDEditor
          autofocus={false}
          ref={MDRef}
          height={'300px'}
          initialValue={values[0]}
          hideModeSwitch
          initialEditType="wysiwyg"
          useCommandShortcut={true}
          onChange={() => {
            let value = MDRef.current.getInstance().getMarkdown().slice(0, 3000)
            let newValue = {
              field,
              operation,
              values: [value],
              valueDetails: []
            }
            dispatch({
              type: 'set_property_values',
              propertyValues: {
                ...state.propertyValues,
                [property.id]: newValue
              }
            })
            preloadDetailMap.current[property.id] = newValue;
          }}
          previewStyle="vertical"
          theme={localStorage.hasOwnProperty('dark') ? "dark" : "white"}
          usageStatistics={false}
          toolbarItems={
            [
              ['heading', 'bold', 'italic', 'strike'],
              ['hr', 'quote'],
              ['ul', 'ol', 'indent', 'outdent'],
              ['table', 'link'],
              ['code', 'codeblock']
            ]
          }
        />
      )
    }
    if (['owner', 'steward'].includes(property.id)) {
      let dataRole;
      if (property.id === 'owner') dataRole = 'DATA OWNER';
      if (property.id === 'steward') dataRole = 'DATA STEWARD';
      return (
        <SearchSelector
          url='/solr/search/select'
          testID={`add-property-search-${property.id}`}
          params={{
            q: `${searchValue}*`,
            fq: 'object_type_srt:USER AND account_type_srt:"USER_ACCOUNT" AND -merge_type_srt:CANDIDATE' +
              (userFilter === 'data_roles' ? ` AND data_role_kc_msrt:"${dataRole}"` : '') +
              (values?.length > 0 ? ` AND -id:(${values.join(' OR ')})` : ''),
            rows: 10
          }}
          searchValue={searchValue}
          autoSuggestion
          setSearchValue={setSearchValue}
          placeholder='Search for a user'
          onResultClick={el => {
            onSelectInstance(el)
          }}
          borderColour={values.length === 0 ? theme.palette.error.main : undefined}
        />
      )
    }
    return (
      <SearchSelector
        url='/solr/search/select'
        testID={`add-property-search-${property.id}`}
        params={{
          name: `${searchValue}*`,
          fq: `parent_id_srt:${property.id}` + (values?.length > 0 ? ` AND -id:(${values.join(' OR ')})` : ''),
          rows: 10
        }}
        autoSuggestion
        height={51}
        borderColour={values.length > 0 ? undefined : theme.palette.error.main}
        scrollable
        searchValue={searchValue}
        setSearchValue={setSearchValue}
        placeholder={`Search for a ${property.name_txt}`}
        onResultClick={el => {
          onSelectInstance(el)
        }}
      />
    )
  }

  const onChangeOperation = value => {
    if (value === 'CLEAR') {
      let newValue = {
        field,
        operation: value,
        values: [],
        valueDetails: []
      };
      dispatch({
        type: 'set_property_values',
        propertyValues: {
          ...state.propertyValues,
          [property.id]: newValue
        }
      })
      preloadDetailMap.current[property.id] = newValue;
    } else {
      let newValue = {
        field,
        operation: value,
        values,
        valueDetails
      };
      dispatch({
        type: 'set_property_values',
        propertyValues: {
          ...state.propertyValues,
          [property.id]: newValue
        }
      })
      preloadDetailMap.current[property.id] = newValue;
    }
  }

  const onRemoveValue = v => {
    let newValue = {
      field,
      operation,
      values: values.filter(val => val !== v.id),
      valueDetails: valueDetails.filter(val => val.id !== v.id)
    };
    dispatch({
      type: 'set_property_values',
      propertyValues: {
        ...state.propertyValues,
        [property.id]: newValue
      }
    })
    preloadDetailMap.current[property.id] = newValue;
  }

  return (
    <div className={classes.root}>
      <Typography className={classes.titleText}>
        {property.name_txt}
      </Typography>
      <Select
        value={operation}
        className={classes.selector + ' ' + classes.editTypeSelector}
        data-test-id={`edit-${property.name_txt}-operation-selector`}
        onChange={event => onChangeOperation(event.target.value)}
        disableUnderline
      >
        {
          [
            { name: 'Add to existing', value: 'ADD_TO_EXISTING' },
            { name: 'Replace', value: 'REPLACE' },
            { name: 'Clear', value: 'CLEAR' }
          ]
            .filter(el => getValidOperations().includes(el.value))
            .map(el => (
              <MenuItem className={classes.menuItem} value={el.value}>
                {el.name}
              </MenuItem>
            ))
        }
      </Select>
      <div style={{ flexGrow: 1, maxWidth: 640, marginRight: 64 }}>
        {operation !== 'CLEAR' && getInputComponent()}
        {
          property.id !== 'description' && valueDetails.length > 0 && operation !== 'CLEAR' &&
          <div className={classes.selectedContainer}>
            <Typography className={classes.selectedContainerText}>Selected:</Typography>
            {
              valueDetails.map(v => {

                if (['owner', 'steward'].includes(property.id)) {
                  return (
                    <UserChip
                      user={v}
                      onRemove={() => onRemoveValue(v)}
                      bottomMargin={10}
                    />
                  )
                }

                let borderColour = theme.palette.chipBorder.main;
                let bgColour = theme.palette.chip.main;
                let textColour = theme.palette.primaryText.main;

                if (v.object?.name === 'COLLECTION_INSTANCE') {
                  borderColour = getCollectionColour(v.parent.name, 'manual', v.name).colour
                  bgColour = borderColour
                  textColour = getFontColourByBackground(bgColour)
                }

                if (v.parent?.id === collectionIds.category) {
                  borderColour = v.properties[1]
                  bgColour = borderColour
                  textColour = getFontColourByBackground(bgColour)
                }

                if (property.id === 'verified_for') {
                  borderColour = getCollectionColour('verified').colour
                  bgColour = borderColour;
                  textColour = getFontColourByBackground(bgColour)
                }

                if (property.id === 'not_verified_for') {
                  borderColour = getCollectionColour('verified not').colour
                  bgColour = borderColour;
                  textColour = getFontColourByBackground(bgColour)
                }

                if(property.id===collectionIds.domain){
                  borderColour = getCollectionColour('domain').colour
                  bgColour = borderColour;
                  textColour = getFontColourByBackground(bgColour)
                }

                if(property.id===collectionIds.classification){
                  borderColour = getCollectionColour('classification').colour
                  bgColour = borderColour;
                  textColour = getFontColourByBackground(bgColour)
                }

                return (
                  <div
                    className={classes.valueChip}
                    style={{
                      border: `1px solid ${borderColour}`,
                      background: bgColour
                    }}
                  >
                    <span className='overflow-text' style={{ color: textColour }}>
                      {v.name || v.name_txt}
                    </span>
                    <IconButton onClick={() => onRemoveValue(v)} className={classes.valueClearButton} style={{ background: bgColour }}>
                      {getIconComponent({ label: 'clear', size: 16, colour: textColour })}
                    </IconButton>
                  </div>
                )
              })
            }
          </div>
        }
      </div>
      <div style={{ flex: '0 0 100px' }}>
        <IconButton
          onClick={() => onRemoveProperty(property.id)}
          style={{ marginTop: 6 }}
        >
          {getIconComponent({ label: 'clear', size: 24, colour: theme.palette.primaryText.light })}
        </IconButton>
      </div>
    </div>
  )
}

PropertyItem.propTypes = {
  classes: PropTypes.object.isRequired,
  state: PropTypes.object.isRequired,
  property: PropTypes.object.isRequired,
  dispatch: PropTypes.func.isRequired,
}

export default withTheme()(withStyles(styles)(PropertyItem));