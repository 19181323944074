import React, { useState, useEffect, useReducer } from 'react';
import { withTheme, withStyles, Typography, LinearProgress } from '@material-ui/core';
import DeadEnd from '../../components/Generic/Page/DeadEnd';
import ProfileHeader from '../../components/UI/ProfileHeader/ProfileHeader3';
import ProfileLayout from '../../components/UI/ProfileLayoutNew/ProfileLayoutNew';
import DatabaseBody from '../../components/DatabaseProfile/DatabaseBody/DatabaseBody';
import TabBar from '../../components/UI/TabBar/TabBar';
import { handleShareClick, toTitleCase, getDispFields, removeUrlQueryArg, getUserRoles } from '../../utilities';
import {  setInitialState } from '../../utilities';
import ProfileSideBar from '../../components/UI/ProfileSideBar/ProfileSideBar';
import axiosCerebrum from '../../axios-cerebrum';
import { connect, useDispatch } from 'react-redux'
import * as actions from '../../store/actions/index';
import * as actionTypes from '../../store/actions/actionTypes';
import ProfileButton from '../../components/UI/Buttons/ProfileButton'
import DropdownButton from '../../components/UI/Buttons/DropdownButton'
import 'url-search-params-polyfill';
import axiosSolr from '../../axios-solr'
import {addHistory} from '../../HistoryManager'
import FollowButton from '../../components/UI/Buttons/FollowButton'
import { globalListenerRef } from '../../GlobalListenerRef';
import { checkIsFollowed, checkLineageLinkable, checkProfileEditable } from '../../permissionChecker';
import { lineageObjectsMap } from '../../components/UI/Lineage/utils';
import ProfileModalController from '../../components/UI/ProfileModalController/ProfileModalController';

const styles = theme => ({
  root: {
    width: '100%',
    display: 'flex',
    flexDirection: 'column'
  },
  appBar: {
    zIndex: 10,
    marginBottom:12
  },
  normalText:{
    color:theme.palette.primaryText.main
  }
});

const initialState = {
  tagModalOpen:false,
  tabState: 0,
  noteTabState:0,
  noteDetailMap:{},
  // basic table data
  basicData: null,
  // creator data
  creatorData: null,
  showDeletedSchemas:false,
  showReferenceSchemas:false,
  // insights 
  insightsData: null,

  // usage
  // usageData: null,
  // usageError: null,
  // usageLoading: null,
  searchFilter:'',
  // usage
  usageData: null,
  usageError: null,
  usageLoading: null,
  usagePeriod:3,
  usageType:'direct',
  lineageData:{
    tabState:0,
    upstreamView:'basic_map',
    downstreamView:'basic_map',
    upstreamObjects:['DATABASE','TOOL'],
    downstreamObjects:['DATABASE','TOOL'],
    
    upstreamListSort:'LAST_USED_DESC',
    downstreamListSort:'LAST_USED_DESC'
  },
  referenceFilter:'name_srt asc',

  //selectedDate:new Date()
  selectedDate: null,
  sortBy: 'total_usage_srt desc',
  //isLoadingMore:false,
};

function reducer(state, action) {
  switch (action.type) {
    case 'set_tab_state':
      return {
        ...state,
        tabState: action.tabState,
      }
    case 'set_list_modal_open':{
      return {
        ...state,
        listModalOpen:action.listModalOpen
      }
    }
    case 'set_warning_modal_open': {
      return {
        ...state,
        warningModalOpen: action.warningModalOpen
      }
    }
    case 'set_child_count':
      return {
        ...state,
        childCount:action.childCount
      }
    case 'set_note_modal_open':{
      return {
        ...state,
        noteModalOpen:action.noteModalOpen
      }
    }
    case 'set_notes':
      return {
        ...state,
        notesLoading:action.notesLoading,
        notesError:action.notesError,
        notes:action.notes
      }
    case 'set_survey_data':
      return {
        ...state,
        surveyData:action.surveyData,
        surveyLoading:action.surveyLoading,
        surveyError:action.surveyError
      }
    case 'set_note_tab_state':{
      return {
        ...state,
        noteTabState:action.noteTabState
      }
    }
    case 'set_note_detail_map':{
      return {
        ...state,
        noteDetailMap:action.noteDetailMap
      }
    }
    case 'set_lineage_data':
      return {
        ...state,lineageData:action.lineageData
      }
    case 'set_link_object_data':
      return {
        ...state,
        linkObjectData:action.linkObjectData
      }
    case 'set_basic_data':
      return {
        ...state,
        basicData: action.basicData,
        basicDataError:action.basicDataError,
        basicDataLoading:action.basicDataLoading
      }
    case 'set_access_modal_open':{
      return {
        ...state,
        accessModalOpen:action.accessModalOpen
      }
    }
    case 'set_access_roles':
      return {
        ...state,
        accessRolesLoading:action.accessRolesLoading,
        accessRolesError:action.accessRolesError,
        accessRoles:action.accessRoles
      }
    case 'set_collection_instances':{
      return {
        ...state,
        collectionInstancesData:action.collectionInstancesData
      }
    }
    case 'set_editing_description':
      return {
        ...state,
        editingDescription: action.editingDescription
      }
    case 'set_insights_data':
      return {
        ...state,
        insightsData: action.insightsData,
      }
    case 'set_usage_type':
      return {
        ...state,
        usageType:action.usageType
      }
    case 'set_usage_data':
      return {
        ...state,
        usageData: action.usageData,
        usageError: action.usageError,
        usageLoading: action.usageLoading
      }
    case 'set_usage_active_series':
      return {
        ...state,
        usageActiveSeries: action.usageActiveSeries
      }
    case 'set_usage_period':
      return {
        ...state,
        usagePeriod:action.usagePeriod
      }
    case 'set_schemalist_data':
      return {
        ...state,
        schemalistData: action.schemalistData,
        schemalistError: action.schemalistError,
        schemalistLoading: action.schemalistLoading,
      }
    case 'set_search_filter':
      return {
        ...state,
        searchFilter:action.searchFilter
      }
    case 'set_show_deleted_schemas':
      return {
        ...state,
        showDeletedSchemas:action.showDeletedSchemas
      }
    case 'set_reference_child_count':
      return {
        ...state,
        referenceChildCount:action.referenceChildCount
      }
    case 'set_show_reference_schemas':
      return {
        ...state,
        showReferenceSchemas:action.showReferenceSchemas
      }
    case 'set_issue_upstream_data':
      return {
        ...state,
        issueUpstreamData: action.issueUpstreamData,
        issueUpstreamError: action.issueUpstreamError,
        issueUpstreamLoading: action.issueUpstreamLoading
      }
    case "set_selected_date":
      return {
        ...state,
        selectedDate: action.selectedDate
      }
    case "set_sort":
      return { ...state, sortBy: action.sortBy }
    case 'set_following':{
      return {
        ...state, following:action.following
      }
    }
    case 'set_additional_properties':
      return {
        ...state,
        additionalProperties:action.additionalProperties,
        additionalPropertiesLoading:action.additionalPropertiesLoading,
        additionalPropertiesError:action.additionalPropertiesError
      }
    case 'set_additional_properties_modal_open':
      return {
        ...state,
        additionalPropertiesModalOpen:action.additionalPropertiesModalOpen,
      }
    default:
      console.log(action)
      throw new Error("Reducer action not supported.", action);
  }
}


const DatabaseProfile = props => {

  const {
    match,
    theme,
    history,
    classes,
    sessionData,
    pageCache,
    storePageCache,
  } = props;

  const urlSearch = new URLSearchParams(window.location.search);
  const tabName = urlSearch.get('tabName');
  const reduxDispatch = useDispatch();

  const roles = getUserRoles(sessionData.user_role)
  const [state, dispatch] = useReducer(reducer, setInitialState(pageCache,initialState));
  const [linkModalOpen, setLinkModalOpen] = useState(false);

  let isLineageLinkable = checkLineageLinkable({sessionData})


  const getTabOptions = data => {
    // return [state.childCount?'SCHEMAS':undefined, 'DETAILS','KNOWLEDGE', roles.every(el=>el==='90') || data.usage_txt==='UNKNOWN'?undefined:'USAGE', 'LINEAGE'].filter(el=>el!==undefined);
    return [state.childCount?'SCHEMAS':undefined, 'DETAILS','KNOWLEDGE', roles.every(el=>el==='90')?undefined:'USAGE', 'LINEAGE'].filter(el=>el!==undefined);
  }

  useEffect(()=>{
    if(!state)return;
    storePageCache({cacheID:window.location.href,...state})
  },[state,storePageCache])

  const fetchList = () => {
    dispatch({type:'set_basic_data',basicDataLoading:state.basicData?false:true,basicData:state.basicData});
    axiosCerebrum
      .get(
        `/api/databases/${match.params.id.toLowerCase()}`,
      )
      .then(async cerebrumResponse=>{
        let fullResponse = cerebrumResponse.data;
        if(!fullResponse){
          dispatch({ type: 'set_basic_data', basicDataError: true})
          return;
        }
        if(fullResponse.active_flag === false){
          !state.basicData && dispatch({type:'set_show_deleted_schemas',showDeletedSchemas:true})
        }
        if(fullResponse.reference===true){
          dispatch({type:'set_show_reference_schemas',showReferenceSchemas:true})
        }
        let sourceData;
        await axiosCerebrum.get(`/api/sources/${fullResponse.source_id}`).then(response=>sourceData=response.data).catch(error=>{console.log(error)})
        addHistory({url:window.location.pathname, title: getDispFields(fullResponse,'dispTitle'), subTitle:getDispFields(fullResponse,'dispSubtitle'),object:fullResponse,type:'profile'})
        axiosSolr
          .get(`/solr/search/select`,{params:{q:"*",fq:`id:${fullResponse.id}`,fl:'*'}})
          .then(response=>{
            dispatch({ type: 'set_basic_data', basicData: {...(state.basicData||{}),...fullResponse,...(response.data.response.docs[0]||{}), source_data:sourceData}})
            dispatch({type:'set_child_count',childCount:response.data.response.docs[0]?response.data.response.docs[0].child_object_count_srt:0})
          })
          .catch(error=>{
            dispatch({ type: 'set_basic_data', basicData: fullResponse})
          })
      })
      .catch(error=>{
        dispatch({ type: 'set_basic_data', basicDataError: true})
      })
  }

  const followDataFetch = () => {
    checkIsFollowed({objectId:match.params.id.toLowerCase()})
      .then(followed=>{
        dispatch({type:'set_following',following:followed})
      })
  }

  useEffect(()=>{

    if(state.followData===undefined){
      followDataFetch()
    }
    // eslint-disable-next-line
  },[state.usagePeriod,state.usageType])


  useEffect(() => {
    if(!state.basicData){
      fetchList();
      return;
    }
  // eslint-disable-next-line
  }, [])

  useEffect(()=>{
    window.scrollTo(0,0)
  },[state.tabState])

  useEffect(()=>{
    if(tabName && state.basicData && !isNaN(state.childCount)){
      let tabOptions = getTabOptions(state.basicData);
      if(!tabOptions.includes(tabName.toUpperCase()))return;
      let presetTabState = tabOptions.indexOf(tabName.toUpperCase());
      window.history.replaceState(null, null, removeUrlQueryArg({url:window.location.toString(),keys:['tabName']}));
      dispatch({type:'set_tab_state',tabState:presetTabState})
    }
    // eslint-disable-next-line
  },[state.basicData, state.childCount])

  useEffect(()=>{
    window.removeEventListener('message',globalListenerRef.profileLinkModalListener)
    if(!isLineageLinkable)return;
    globalListenerRef.profileLinkModalListener = (msg) => {
      if(msg.data.open_profile_link_modal ){
        setLinkModalOpen({relations:['SOURCE_OF','SOURCE_FROM'], linkableObjects:lineageObjectsMap.DATABASE})
      }
    }
    window.addEventListener('message',globalListenerRef.profileLinkModalListener)

    return ()=>{
      window.removeEventListener('message',globalListenerRef.profileLinkModalListener)
    }

  // eslint-disable-next-line
  },[isLineageLinkable])

  if (state.basicDataLoading) {
    return (
      <div style={{ textAlign:'center', width: '18.75rem',margin:'20vh auto'}}>
        <Typography className={classes.normalText}>Loading</Typography>
        <LinearProgress style={{ marginTop: '1.5rem' }} color="secondary" />
      </div>
    )
  }

  if (state.basicDataError) {
    return (
      <DeadEnd/>
    )
  }

  if(!state.basicData){
    return <div></div>
  }

  const isShell = state.basicData.reference!==false;

  let buttons = [];


  buttons.push(
    <ProfileButton
      onClick={() => handleShareClick()}
      iconLabel='share'
      iconColour={theme.palette.primaryText.light}
      iconOnly={true}
      tooltip={'Share link'}
    />
  )

  const onCasCaseUpdate = () => {
    reduxDispatch({
      type:actionTypes.SET_BULK_EDIT_PARAM,
      data:{
        url:'/solr/search/select',
        type:'solr',
        params: {
          q: "*",
          fq: `(source_id_srt:${state.basicData.source_id} OR source_id_srt:998) AND (database_srt:"${state.basicData.name}" OR id:${state.basicData.id})`,
        },
        editMode: 'cascade',
        cascadeParentObj: state.basicData,
        redirectUrl:window.location.pathname,
        fileName:`Cascade update from ${state.basicData.name} database`,
      }
    })
    
		history.push('/bulk_update')
  }

  let optionTitle = []
  let optionList = []

  optionTitle.push('OPEN IN APPLICATIONS')
  optionList.push(
    {onClick: onCasCaseUpdate,iconLabel:'bulk_action_cascade',text:'Cascade Update',group:'OPEN IN APPLICATIONS'},
  )
  
  buttons.push(
    <DropdownButton
      iconLabel='dot_menu'
      tooltip="More actions"
      iconOnly={true}
      iconColour={theme.palette.primaryText.light}
      title={optionTitle}
      optionList={optionList}
      hideSectionTitle
    />
  )

  buttons.push(
    <ProfileButton
      onClick={() => dispatch({type:'set_list_modal_open',listModalOpen:true})}
      iconLabel='add_list'
      iconColour={theme.palette.primaryText.light}
      iconOnly={true}
      tooltip={'Add to List'}
    />
  )



  // if(!isShell){
    buttons.push(
      <DropdownButton
        iconLabel='add'
        text='ADD'
        optionList={[
          {onClick:() => dispatch({ type: 'set_warning_modal_open', warningModalOpen: true }), iconLabel: 'warning_notice', text: 'Add warning notice', group: 'NOTICES'},
          {onClick:()=>dispatch({type:'set_note_modal_open',noteModalOpen:true}),iconLabel:'note',text:'Add Knowledge'},
          {onClick:()=>dispatch({type:'set_note_modal_open',noteModalOpen:{isQuestion:true}}),iconLabel:'question',text:'Ask Question'},
          checkProfileEditable({sessionData, isStewardOrOwner:state.isStewardOrOwner})?{onClick:()=>dispatch({type:'set_additional_properties_modal_open',additionalPropertiesModalOpen:true}),iconLabel:'info',text:'Add Property'}:undefined
        ]}
      />
    )
  // }

  buttons.push(
    <FollowButton
      following={state.following}
      object={state.basicData}
      dispatch={dispatch}
    />
  )


  let bannerdisplayText,bannerCause,bannerVisibility;
  let data = state.basicData;
    
  if(data && data.active_flag === false ){
    bannerdisplayText =  'This database has been deleted';
    bannerVisibility = 'visible'
    bannerCause = 'active'
  }

  if (data && data.source_data && data.source_data.active_flag===false) {
    bannerdisplayText = 'The source of this database has been deactivated in K. Information on this database is dated and may no longer be accurate';
    bannerCause = 'active';
    bannerVisibility = 'visible'
  }

  if (data && isShell){
    let sourceName = state.basicData.source_data?state.basicData.source_data.name:'Unkown Source'
    if(data.reference_sources){
      if(data.reference_sources.length===0){
        bannerdisplayText=`This Database is referenced in unknown source. Limited metadata available.`
      }
      else if(data.reference_sources.length===1){
        bannerdisplayText=`This Database is referenced in ${data.reference_sources[0].reference_source.name}. ${toTitleCase(data.name)} is not connected to K. Limited metadata available.`
      }else{
        // bannerdisplayText=(
        //   <Typography>
        //     This Database is referenced in <span onClick={()=>dispatch({type:'set_tab_state',tabState:getTabOptions(state.basicData).indexOf('RELATED')})} style={{textDecoration:'underline',cursor:'pointer'}}>multiple Tools</span>. {sourceName} is not connected to K. Limited metadata available.
        //   </Typography>
        // )
        bannerdisplayText=(
          <Typography>
            This Database is referenced in multiple Tools. {sourceName} is not connected to K. Limited metadata available.
          </Typography>
        )
      }
    }
    else{
      bannerdisplayText= 'This Source is referenced in content but not integrated as a source. Limited metadata available.';
    }
    bannerCause = 'shell';
    bannerVisibility = 'visible'
  }

  const tabOptions = getTabOptions(data)

  return (
    <div>
      <ProfileModalController
        state={state}
        dispatch={dispatch}
        linkModalOpen={linkModalOpen}
        setLinkModalOpen={setLinkModalOpen}
        modalMapping={['list','link','warning','note','additional_property']}
        history={history}
      />
      <ProfileLayout
        header={(
            <ProfileHeader
              tabOptions={tabOptions}
              type='database'
              title={state.basicData.name || state.basicData.id}
              shouldLoadBreadCrumb
              label={'database'}
              buttons={buttons}
              data={state.basicData}
              history={history}
              state={state}
              isShell={isShell}
              dispatch={dispatch}
              bannerdisplayText={bannerdisplayText}
              bannerVisibility={bannerVisibility}
              bannerCause={bannerCause}
              shouldLoadLinkedInstance
              onClickAddTag={()=>{
                setLinkModalOpen({
                  linkableObjects:['COLLECTION_INSTANCE'],
                  relations:['MEMBER_OF'],
                  onLinkUpdated:()=>window.postMessage({reload_header_instance:true},document.location.protocol + "//" + document.location.hostname+':'+document.location.port)
                })
              }}
            />
          )}
        tabBar={
          <TabBar
            tabOptions={tabOptions}
            tabState={state.tabState}
            setTabState={value => dispatch({ type: 'set_tab_state', tabState: value })}
            disableUnderline={true}
            minWidth={200}
            maxWidth={200}
          />
        }
        body={
          <DatabaseBody
            tabOptions={tabOptions}
            nodeKey={state.basicData.signature}
            isShell={isShell}
            history={history}
            tabState={state.tabState}
            data={state.basicData}
            state={state}
            dispatch={dispatch}
            sessionData={sessionData}
            fetchList={fetchList}
            isLineageLinkable={isLineageLinkable}
          />
        }
        hideSideBar={['LINEAGE'].includes(tabOptions[state.tabState])}
        hideHeader={['LINEAGE'].includes(tabOptions[state.tabState])}
        disableMinContentHeight={['LINEAGE'].includes(tabOptions[state.tabState])}
        sideBar={
          <ProfileSideBar
            tabOptions={tabOptions}
            history={history}
            state={state}
            dispatch={dispatch}
            basicData={state.basicData}
            sessionData={sessionData}
            fetchList={fetchList}
            cerebrumLabel={'databases'}
            data={state.basicData}
            mapping={['tags','description','category','classification','domain','verified','verifiedNot','owner','stewardedBy','lineage','upstreamSources','downstreamSources','sourceType','lastUpdated','topTools','knowledge', 'topTeams', 'lastUsed']}
          />
        }
      >
      </ProfileLayout>
    </div>
  )

}

const mapStateToProps = state => {
  return {
    pageCache: state.pageCache.pageCache,
  };
}

const mapDispatchToProps = dispatch => {
  return {
    storePageCache: (state) => dispatch(actions.storePageCache(state))
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(withTheme()(withStyles(styles)(DatabaseProfile)));